export class Constants {
  static readonly CANCEL_PERMIT_EVENT_LABEL = 'Opzeggen knop klik';
  static readonly SEEN_COOKIE_STATEMENT = 'Svnl-seenCookieStatement';

  static readonly PROFILE_MENU_ICON = 'person';
  static readonly PERMITS_MENU_ICON = 'credit_card';
  static readonly FISHINGWATERS_MENU_ICON = 'list';
  static readonly INVITE_MENU_ICON = 'person_add';
  static readonly CONTACT_MENU_ICON = 'mail';
  static readonly MISCONDUCT_MENU_ICON = 'report';
  static readonly ELECTIONS_ICON = 'task_alt';
  static readonly OPEN_IN_NEW_ICON = 'open_in_new';

  static readonly BASE_MAP_URL =
    'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

  static readonly disciplinaryLawLink =
    'https://www.sportvisserijnederland.nl/over-ons/organisatie/tuchtrechtspraak.html';
  static readonly questionsLink =
    'https://www.sportvisserijnederland.nl/over-ons/veelgestelde-vragen';

}

